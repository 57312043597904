




































































import { mapGetters, mapState } from 'vuex'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'
import { THREE_DAYS } from '~/constants/duration'
import { FLASH_NS } from '~/store/modules/shared/flash/state'
import { capitalizeString } from '~/utils/string'
import { ciTimes } from '~/icons/source/regular/times'
import { APP_NS } from '~/store/modules/shared/app/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import UserAgentService from '~/services/UserAgentService'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    site: {
      type: String,
      required: false,
      default: 'car'
    }
  },
  data() {
    return {
      forceClose: false,
      ready: false
    }
  },
  computed: {
    ...mapGetters(STORAGE_NS, {
      getItem: 'getItem'
    }),
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot',
      isCar: 'isCar'
    }),
    ...mapDeps({
      userAgentService: UserAgentService
    }),
    ...mapState(FLASH_NS, {
      clientFlashMessage: state => state.clientFlashMessage
    }),
    icons() {
      return {
        times: ciTimes
      }
    },
    isAndroid() {
      return this.userAgentService.isAndroidOs()
    },
    isSupportedMobile() {
      return this.isIos || this.isAndroid
    },
    isIos() {
      return this.userAgentService.isIos()
    },
    goneToApp() {
      const landing = this.getItem('landing')
      if (landing?.gotoapp) {
        return landing.gotoapp.goneToApp
      }
      return false
    },
    closeCount() {
      const landing = this.getItem('landing')
      if (landing?.gotoapp) {
        return landing.gotoapp.closeCount
      }
      return 0
    },
    closedLast3Days() {
      const landing = this.getItem('landing')
      if (landing?.gotoapp) {
        const timestamp = landing.gotoapp.lastClose
        if (timestamp) {
          return (
            timestamp &&
            Math.floor(Math.abs(timestamp - Date.now()) / 36e5) < THREE_DAYS
          )
        }
      }
      return false
    },
    show() {
      return (
        this.ready &&
        !this.forceClose &&
        this.closeCount < 5 &&
        !this.closedLast3Days &&
        !this.goneToApp &&
        !this.clientFlashMessage &&
        this.linkToStore &&
        this.appImage
      )
    },
    linkToStore() {
      if (this.isAndroid) {
        if (this.site === 'car') {
          return 'https://play.google.com/store/apps/details?id=com.car.gr'
        }
        if (this.site === 'plot') {
          return 'https://play.google.com/store/apps/details?id=com.plot.gr'
        }
      } else if (this.isIos) {
        if (this.site === 'car') {
          return 'https://apps.apple.com/app/car-gr/id1278899131'
        }
        if (this.site === 'plot') {
          return 'https://apps.apple.com/app/plot-gr/id1628294378'
        }
      }
      return null
    },
    appImage() {
      if (this.site === 'car') {
        return require('~/assets/img/plot/index/car-app.png')
      } else if (this.site === 'plot') {
        return require('~/assets/img/plot/index/plot-app.png')
      }
      return null
    }
  },
  async mounted() {
    await this.$nextTick()
    this.ready = true
  },
  methods: {
    closeGoToApp() {
      const closeCount = this.closeCount + 1
      const lastClose = Date.now()
      this.$store.dispatch('storage/set', {
        landing: {
          gotoapp: {
            closeCount,
            lastClose
          }
        }
      })
      this.forceClose = true
    },
    goToApp() {
      this.$store.dispatch('storage/set', {
        landing: {
          gotoapp: {
            goneToApp: true
          }
        }
      })
      this.closeGoToApp()

      // maybe add analytics event here?
    },
    capitalizeString(str: string) {
      return capitalizeString(str)
    }
  }
})
