

























































































import { mapGetters } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import {
  SET_FETCHABLE_FEEDS,
  SET_LANDING_CLASSIFIEDS_COUNT,
  SET_QUICK_SEARCHES
} from '~/store/modules/shared/index/mutation-types'
import { INDEX_NS } from '~/store/modules/shared/index/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import IntroText from '~/components/plot/landing/IntroText.vue'
import { I18N_NS } from '~/store/modules/shared/i18n/state'
import QuickSearches from '~/components/plot/landing/QuickSearches.vue'
import AppPrompt from '~/components/plot/landing/AppPrompt.vue'
import CWaves from '~/components/shared/configurable/separators/CWaves.vue'
import { formatNumber } from '~/utils/number'
import { Config } from '~/models/quick-search/types'
import LandingUserService from '~/services/user/LandingUserService'
import IndexFeeds from '~/components/plot/IndexFeeds.vue'
import { CategoryId } from '~/models/category/types'
import {
  QUICK_SEARCH_NS,
  QuickSearchState
} from '~/store/modules/shared/quickSearch/state'
import { SET_CONFIG } from '~/store/modules/shared/quickSearch/mutation-types'
import Facets from '~/components/shared/quick-search/facets/Facets.vue'
import { useQuickSearchPage } from '~/compositions/quick-search/useQuickSearchPage'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  useStore
} from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import RecentSearchModal from '~/components/plot/landing/RecentSearchModal/RecentSearchModal.vue'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useDep } from '~/compositions/dependency-container'

export default defineComponent({
  components: {
    CWaves,
    QuickSearches,
    IntroText,
    RecentSearchModal,
    Facets,
    AppPrompt,
    IndexFeeds
  },
  async asyncData({ store, $s, app: { i18n }, $dep }) {
    const landingUserService = $dep(LandingUserService)

    const config: Config = {
      baseUrl: '/api/plot/quick-search/',
      title: i18n.t('search classifieds::plot_landing_page'),
      rootCategoryId: CategoryId.PLOT,
      facetsCols: {
        offertype: 3,
        category: 2,
        location: 4
      },
      hideOnMobileWhenNoSearchables: {
        category: true
      },
      backgroundImagesUrls: [
        $s(
          '/nuxt-static/img/quick-search/plot-index-splash/plot-index-splash-1.jpg'
        ),
        $s(
          '/nuxt-static/img/quick-search/plot-index-splash/plot-index-splash-2.jpg'
        ),
        $s(
          '/nuxt-static/img/quick-search/plot-index-splash/plot-index-splash-3.jpg'
        ),
        $s(
          '/nuxt-static/img/quick-search/plot-index-splash/plot-index-splash-4.jpg'
        ),
        $s(
          '/nuxt-static/img/quick-search/plot-index-splash/plot-index-splash-5.jpg'
        ),
        $s(
          '/nuxt-static/img/quick-search/plot-index-splash/plot-index-splash-6.jpg'
        ),
        $s(
          '/nuxt-static/img/quick-search/plot-index-splash/plot-index-splash-7.jpg'
        )
      ],
      logo: 'https://static.car.gr/static/images/plot/plot-logo.svg?v=2'
    }

    store.commit(`${QUICK_SEARCH_NS}/${SET_CONFIG}`, config)

    const [count, landingData] = await Promise.all([
      landingUserService.getClassifiedsCount(),
      landingUserService.getLanding('?_site=plot'),
      store.dispatch(`${QUICK_SEARCH_NS}/loadData`)
    ])

    store.commit(`index/${SET_FETCHABLE_FEEDS}`, landingData.fetchableFeeds)
    store.commit(`index/${SET_QUICK_SEARCHES}`, landingData.quickSearches)
    store.commit(`index/${SET_LANDING_CLASSIFIEDS_COUNT}`, count)
  },
  setup() {
    const { state } = useNamespacedStore<QuickSearchState>(QUICK_SEARCH_NS)
    useQuickSearchPage()

    const randomBackgroundImageUrl = computed(
      () => state.randomBackgroundImageUrl
    )

    const containerStyle = computed(() => ({
      backgroundImage: `url(${randomBackgroundImageUrl.value})`
    }))

    const bgIsThessaloniki = computed(
      () =>
        randomBackgroundImageUrl.value &&
        randomBackgroundImageUrl.value.endsWith('plot-index-splash-1.jpg')
    )

    const userLandingService = useDep(LandingUserService)
    const store = useStore()

    const unsubscribe = store.subscribeAction({
      after: async action => {
        if (!process.client) {
          return
        }
        if (action.type === `${USER_NS}/loadUser`) {
          const landing = await userLandingService.getLanding('?_site=plot')
          const { fetchableFeeds, quickSearches } = landing
          store.commit(`index/${SET_FETCHABLE_FEEDS}`, fetchableFeeds)
          store.commit(`index/${SET_QUICK_SEARCHES}`, quickSearches)
        }
      }
    })

    onBeforeUnmount(() => {
      unsubscribe()
    })

    return { containerStyle, bgIsThessaloniki, CategoryId }
  },
  data: () => ({
    scrolledToFeeds: false,
    showRecentSearches: false,
    categoryMobileShown: false,
    showRecentSearchModal: false
  }),
  computed: {
    ...mapGetters(USER_NS, {
      isAgent: 'isOfTypeDealer',
      isAdmin: 'isAdmin',
      userHasPermission: 'hasPermission'
    }),
    ...mapGetters(INDEX_NS, {
      classifiedsCount: 'classifiedsCount'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    ...mapGetters(I18N_NS, {
      locale: 'locale'
    }),
    formattedClassifiedCount(): string {
      return formatNumber(this.classifiedsCount)
    },
    offerBtnLink() {
      if (this.isAdmin) {
        return '/admin/users/search/?site=plot'
      }
      return this.$dep(LegacyUrlService).getNewClassifiedUrl()
    },
    icons() {
      return {
        plus: faPlus
      }
    }
  },
  methods: {
    quickSearchVisibilityChange(status) {
      const header = document.getElementById('header-section')
      if (!header) {
        return
      }

      if (status === false && header.classList.contains('in-index')) {
        header.classList.remove('in-index')
      } else if (status === true && !header.classList.contains('in-index')) {
        header.classList.add('in-index')
      }
    },
    openRecentSearchModal() {
      this.trackShowRecentSearchesClick()
      this.showRecentSearchModal = true
    },
    closeRecentSearchModal() {
      this.showRecentSearchModal = false
    },
    trackShowRecentSearchesClick() {
      this.$analytics.recordEvent({
        namespace: 'n_recent_searches_button_clicked',
        action: 'click',
        label: 'show recent searches plot'
      })
    },
    onMobileVisibilityStatus(status: any) {
      // we only care about category
      if (status?.name === 'category') {
        this.categoryMobileShown = !status?.hidden
      }
    }
  }
})
