

























import { mapGetters } from 'vuex'
import { INDEX_NS } from '~/store/modules/shared/index/state'
import QuickSearchItem from '~/components/shared/quick-search/QuickSearchItem.vue'
import CTabs from '~/components/shared/configurable/tab/CTabs.vue'
import CTab from '~/components/shared/configurable/tab/CTab.vue'
import { defineComponent } from '@nuxtjs/composition-api'
import QuickSearchContainer from '~/components/shared/quick-search/QuickSearchContainer.vue'

export default defineComponent({
  components: {
    QuickSearchItem,
    CTabs,
    CTab,
    QuickSearchContainer
  },
  computed: {
    ...mapGetters(INDEX_NS, {
      quickSearches: 'quickSearches'
    })
  }
})
