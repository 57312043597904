























import { formatNumber } from '~/utils/number'
import { QuickSearch } from '~/models/quick-search/types'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    quickSearch: {
      type: Object as PropType<QuickSearch>,
      required: true
    }
  },
  setup() {
    return { formatNumber }
  }
})
