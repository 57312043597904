









import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    fewColumns: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
