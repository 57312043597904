




































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs
} from '@nuxtjs/composition-api'
import { useDep } from '~/compositions/dependency-container'
import { useLogger } from '~/compositions/logger'
import { useNamespacedStore } from '~/compositions/store'
import SearchBucketService from '~/services/search-bucket/SearchBucketService'
import { AppState, APP_NS } from '~/store/modules/shared/app/state'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { CategoryId } from '~/models/category/types'
import { Search } from '~/models/search/types'
import RecentSearchItem from './RecentSearchItem.vue'
import CButton from '~/components/shared/configurable/button/CButton.vue'
import { useSnackbar } from '~/compositions/snackbar'
import { useI18n } from '~/compositions/i18n'

export default defineComponent({
  components: { RecentSearchItem, CButton },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    rootCategory: {
      type: [Number, String] as PropType<CategoryId>,
      default: CategoryId.VEHICLES
    }
  },
  setup(props) {
    const { rootCategory } = toRefs(props)
    const isLoading = ref(false)
    const recentSearches = ref<Search[]>([])
    const { state, getters: searchGetters } = useNamespacedStore<SearchState>(
      CLASSIFIED_SEARCH_NS
    )
    const { getters: appGetters } = useNamespacedStore<AppState>(APP_NS)
    const isPlot = computed(() => appGetters('isPlot'))
    const inPlotSearch = computed(() => searchGetters('inPlotSearch'))
    const isMapSearch = computed(() => state.flags.isMapSearch)
    const snackbar = useSnackbar()
    const { t } = useI18n()
    const searchBucketService = useDep(SearchBucketService)
    const logger = useLogger()

    const addToFavorites = async (id: string) => {
      try {
        isLoading.value = true
        await searchBucketService.addSearchToFavorites(id)
        const recent = recentSearches.value.find(r => r.id === id)
        if (recent) recent.isFavorite = true
        snackbar.success(t('added to favorites searches'))
      } catch (error) {
        logger.captureError(`${error}`)
      } finally {
        isLoading.value = false
      }
    }
    const removeFromFavorites = async (id: string) => {
      try {
        isLoading.value = true
        await searchBucketService.removeSearchFromFavorites(id)
        const recent = recentSearches.value.find(r => r.id === id)
        if (recent) recent.isFavorite = false
        snackbar.success(t('removed from favorites searches'))
      } catch (error) {
        logger.captureError(`${error}`)
      } finally {
        isLoading.value = false
      }
    }
    const fetchData = async () => {
      const data: Search[] = []
      isLoading.value = true
      try {
        let param

        if (rootCategory.value) {
          param = rootCategory.value
        } else if (isMapSearch.value || isPlot.value || inPlotSearch.value) {
          param = CategoryId.PLOT
        }

        const [favorites, recents] = await Promise.all([
          searchBucketService.getFavoriteSearches(param),
          searchBucketService.getRecentSearchesForCategory(param)
        ])
        recents.forEach(t => {
          data.push({ ...t, isFavorite: !!favorites.get(t.id) })
        })
        recentSearches.value = data
      } catch (error) {
        logger.captureError(`${error}`)
      } finally {
        isLoading.value = false
      }
    }
    onMounted(fetchData)
    return {
      recentSearches,
      isLoading,
      addToFavorites,
      removeFromFavorites
    }
  }
})
