import { computed, onBeforeUnmount } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import {
  QUICK_SEARCH_NS,
  QuickSearchState
} from '~/store/modules/shared/quickSearch/state'
import { RESET_PARAMS } from '~/store/modules/shared/quickSearch/mutation-types'

export function useQuickSearchPage() {
  const { commit, state } = useNamespacedStore<QuickSearchState>(
    QUICK_SEARCH_NS
  )
  const breadcrumbs = computed(() => state.breadcrumbs)

  onBeforeUnmount(() => commit(RESET_PARAMS))

  return { breadcrumbs }
}
