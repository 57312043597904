







import { defineComponent } from '@nuxtjs/composition-api'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  props: {
    locale: {
      type: String,
      required: true
    }
  },
  i18n: defineComponentTranslations({
    intro_title: {
      en: 'Properties for sale or rent - Plot.gr',
      el: 'Ακίνητα προς αγορά ή ενοικίαση - Plot.gr'
    },
    intro_text: {
      en:
        '<p>Real Estate Classifieds by Privates or Realtors in <a href="/en/search/?category=20002&amp;geolocation=38.183688194x24.377975464%2C37.720762909x23.514175415">Athens</a>, <a href="/en/search/?category=20002&amp;geolocation=40.683241578x23.066139221%2C40.571848942x22.850189209">Thessaloniki</a> or <a href="/en/search/?category=20002&amp;geolocation=38.349734047x21.994628906%2C38.119162396x21.562728882">Patra</a> and entire Greece. Thousand chances of real estate classifieds. Search for homes, apartments, lofts, studios maisonettes, business premises, lands and much more or place your ad request. An interactive map can help you search and detect the exactly location of an ad.</p><p>Start <a href="/en/search/?category=20002">searching</a> for your ideal property or <a href="/en/classifieds/new/">add your classified</a> for free.</p>',
      el:
        '<p>Αγγελίες ακινήτων από Ιδιώτες &amp; Μεσίτες στην <a href="/search/?category=20002&amp;geolocation=38.183688194x24.377975464%2C37.720762909x23.514175415">Αθήνα</a>, την <a href="/search/?category=20002&amp;geolocation=40.683241578x23.066139221%2C40.571848942x22.850189209">Θεσσαλονίκη</a>, τη <a href="/search/?category=20002&amp;geolocation=38.349734047x21.994628906%2C38.119162396x21.562728882">Πάτρα</a> και σε όλη την Ελλάδα. Χιλιάδες αγγελίες για σπίτια, διαμερίσματα, γκαρσονιέρες, μονοκατοικίες, μεζονέτες, επαγγελματικούς χώρους, οικόπεδα και αγροτεμάχια σε περιμένουν. Αναζήτησε με την βοήθεια διαδραστικού χάρτη το ακίνητο που ψάχνεις και δες τα χαρακτηριστικά του εύκολα &amp; γρήγορα.</p><p>Ξεκίνα την <a href="/search/?category=20002">αναζήτησή</a> σου ή καταχώρησε δωρεάν την <a href="/classifieds/new/">αγγελία</a> σου.</p>'
    }
  })
})
