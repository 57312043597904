














































import { mapGetters } from 'vuex'
import Feed from '~/components/car/classifieds/index/feeds/Feed.vue'
import { DELETE_FETCHABLE_FEED } from '~/store/modules/shared/index/mutation-types'
import { INDEX_NS } from '~/store/modules/shared/index/state'
import RecentFeed from '~/components/car/classifieds/index/feeds/RecentFeed.vue'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { defineComponent } from '@nuxtjs/composition-api'
import IntroText from '~/components/plot/landing/IntroText.vue'
import { I18N_NS } from '~/store/modules/shared/i18n/state'

export default defineComponent({
  components: {
    IntroText,
    Feed,
    RecentFeed
  },
  data() {
    return {
      loadedFeeds: 1, // first feed is always loaded
      scrolledDown: false
    }
  },
  computed: {
    ...mapGetters(INDEX_NS, {
      recentFeed: 'recentFeed',
      userFeeds: 'userFeeds'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc',
      isMobile: 'isMobile'
    }),
    ...mapGetters(I18N_NS, {
      locale: 'locale'
    })
  },
  mounted() {
    window.addEventListener('scroll', this.lazyLoadFeeds, false)

    this.$nextTick(() => {
      this.lazyLoadFeeds()
    })
  },
  beforeDestroy() {
    this.destroyScrollListener()
  },
  methods: {
    async lazyLoadFeeds() {
      if (!this.scrolledDown && window?.scrollY > 0) {
        this.scrolledDown = true
        await this.$nextTick()
      }

      if (this.userFeeds.length && this.loadedFeeds < this.userFeeds.length) {
        for (let i = 0; i < this.userFeeds.length; i++) {
          const feed = this.$refs.userfeed[i]
          if (feed && feed.firstFetchTry === false && feed.isVisible()) {
            feed.fetchFeed()
            this.loadedFeeds++
          }
        }
      }

      if (!this.recentsLoaded) {
        if (this.isPc) {
          // lazy-load is not activated on pc, change this if you ever activate it there
          this.recentsLoaded = true
        } else {
          const recents = this.$refs?.recentFeed
          if (
            recents &&
            recents.firstFetchTry === false &&
            recents.isVisible()
          ) {
            recents.fetchFeed()
            // initially request after 5secs and then fetchinterval takes control
            setTimeout(recents.fetchFeed, 5000)
            recents.startIntervals()
            this.recentsLoaded = true
          }
        }
      }

      if (this.loadedFeeds === this.userFeeds.length && this.recentsLoaded) {
        this.destroyScrollListener()
      }
    },
    destroyScrollListener() {
      window.removeEventListener('scroll', this.lazyLoadFeeds)
    },
    removeFeed(fetchUrl) {
      this.$store.commit(`index/${DELETE_FETCHABLE_FEED}`, fetchUrl)
      this.loadedFeeds--
      this.$nextTick(() => {
        this.lazyLoadFeeds()
      })
    }
  }
})
